import { useEffect, useState } from "react";
import Pagination from "../../components/Pagination";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import moment from "moment";
import { convertToRupiah } from "../../tools/numerictools";
import { useBillingsPartnerHooks } from "../../utils/finance/BillingUtils";
import { useControlPermission } from "../../utils/ActionPermissionUtils";
import { useGroupListHooks } from "../../utils/ProjectsUtilHooks";
import { BILLING_TYPE } from "../../tools/billingdatatools";
import ModalDownloadBillingPartner from "../../components/billing/ModalDownloadBillingPartner";

export default function BillingPartnerPage() {
  let { hasAccess } = useControlPermission();

  const [formFilter, setFormFilter] = useState({
    status: "PAID",
    type: "",
    name: "",
    n: "",
    payment_method: "",
    confirmed_start_date: "",
    confirmed_end_date: "",
    created_start_date: moment().subtract(3, "months").format("YYYY-MM-DD"),
    created_end_date: moment().format("YYYY-MM-DD"),
    amt_from: "",
    amt_until: "",
    project_id: "",
  });
  const [filter, setFilter] = useState({
    status: "PAID",
    type: "",
    name: "",
    n: "",
    payment_method: "",
    confirmed_start_date: "",
    confirmed_end_date: "",
    created_start_date: moment().subtract(3, "months").format("YYYY-MM-DD"),
    created_end_date: moment().format("YYYY-MM-DD"),
    amt_from: "",
    amt_until: "",
    project_id: "",
    page: 1,
    page_size: 10,
  });

  const { projects } = useGroupListHooks();

  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [showModalDownload, setShowModalDownload] = useState(false);

  let billings = useBillingsPartnerHooks({ filter });

  useEffect(() => {
    if (billings.errorMsg) {
      setAlert({ show: true, message: billings.errorMsg, type: "danger" });
    }
  }, [billings.errorMsg]);

  const changeFilter = (name, value) => {
    setFormFilter({ ...formFilter, [name]: value });
  };

  const toggleModalDownload = () => {
    setShowModalDownload(!showModalDownload);
  };

  const applyFilter = () => {
    setFilter({
      ...filter,
      ...formFilter,
      page: 1,
    });
  };

  const resetFilter = () => {
    setFormFilter({
      status: "UNPAID",
      type: "",
      name: "",
      n: "",
      payment_method: "",
      confirmed_start_date: "",
      confirmed_end_date: "",
      created_start_date: moment().subtract(3, "months").format("YYYY-MM-DD"),
      created_end_date: moment().format("YYYY-MM-DD"),
      amt_from: "",
      amt_until: "",
      project_id: "",
    });
    setFilter({
      status: "UNPAID",
      type: "",
      name: "",
      n: "",
      payment_method: "",
      confirmed_start_date: "",
      confirmed_end_date: "",
      created_start_date: moment().subtract(3, "months").format("YYYY-MM-DD"),
      created_end_date: moment().format("YYYY-MM-DD"),
      amt_from: "",
      amt_until: "",
      project_id: "",
      page: 1,
      page_size: 10,
    });
  };

  const gotoPage = (page) => {
    let filter_page = { ...filter, page: page };
    setFilter(filter_page);
  };

  const onSuccess = (message) => {
    billings.getBillings(filter);
    setAlert({ show: true, message: message, type: "success" });
  };

  // const onFailure = (message) => {
  //   billings.getBillings(filter);
  //   setAlert({ show: true, message: message, type: "danger" });
  // };

  return (
    <MainLayout resource_key="partner-infrastructure">
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col">
            <h1 className="h3 mb-0 text-gray-800">Tagihan Pelanggan</h1>
          </div>
          <div className="col-auto">
            {hasAccess("export-partner-infrastructure-billing") && (
              <button className="btn btn-primary" onClick={() => toggleModalDownload()}>
                <i className="fas fa-download"></i> Unduh Data Tagihan
              </button>
            )}
          </div>
        </div>
        <Box title={`Tagihan (Total: ${billings.meta.count})`} loading={billings.loading}>
          <div className="mb-4">
            <div className="row">
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="registration_type">Nomor</label>
                  <input
                    type="text"
                    className="form-control"
                    value={formFilter.n}
                    onChange={(event) => changeFilter("n", event.target.value)}
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="registration_type">Nama Pelanggan</label>
                  <input
                    type="text"
                    className="form-control"
                    value={formFilter.name}
                    onChange={(event) => changeFilter("name", event.target.value)}
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="registration_type">Tipe</label>
                  <select
                    name="type"
                    value={formFilter.type}
                    onChange={(event) => changeFilter("type", event.target.value)}
                    className="form-control"
                    id="status"
                  >
                    <option value="">SEMUA</option>
                    <option value="REGISTRATION">REGISTRATION</option>
                    <option value="CUSTOMER">CUSTOMER</option>
                    <option value="CUSTOMER-RELOCATE">CUSTOMER RELOCATE</option>
                    <option value="ADDITIONAL-ITEM">CUSTOM BILLING</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="registration_type">Status</label>
                  <select
                    name="status"
                    value={formFilter.status}
                    onChange={(event) => changeFilter("status", event.target.value)}
                    className="form-control"
                    id="status"
                  >
                    <option value="">SEMUA</option>
                    <option value="PAID">PAID</option>
                    <option value="UNPAID">UNPAID</option>
                    <option value="CANCELED">CANCELED</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="projectFilter">Grup/Project</label>
                  <select
                    className="form-control"
                    id="projectFilter"
                    value={formFilter.project_id}
                    onChange={(event) => changeFilter("project_id", event.target.value)}
                  >
                    <option value="">SEMUA</option>
                    {projects.map((project, index) => (
                      <option value={project.id} key={index}>
                        {project.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="registration_type">Metode Pembayaran</label>
                  <select
                    name="status"
                    value={formFilter.payment_method}
                    onChange={(event) =>
                      setFormFilter({
                        ...formFilter,
                        payment_method: event.target.value,
                        payment_provider: "",
                      })
                    }
                    className="form-control"
                    id="status"
                  >
                    <option value="">SEMUA</option>
                    <option value="bank-transfer">Bank Transfer</option>
                    <option value="adakita">Adakita</option>
                    <option value="virtual-account">Virtual Account</option>
                    <option value="alfamart">Alfamart</option>
                    <option value="pulsapro">Pulsapro</option>
                    <option value="dukodu">Dukodu</option>
                    <option value="indomaret">Indomaret</option>
                    <option value="qris">QRIS</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label>Tanggal Konfirm Dari</label>
                  <input
                    type="date"
                    className="form-control"
                    value={formFilter.confirmed_start_date}
                    onChange={(event) => changeFilter("confirmed_start_date", event.target.value)}
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label>Tanggal Konfirm Sampai</label>
                  <input
                    type="date"
                    className="form-control"
                    value={formFilter.confirmed_end_date}
                    onChange={(event) => changeFilter("confirmed_end_date", event.target.value)}
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label>Total Pembayaran Minimal</label>
                  <input
                    type="number"
                    className="form-control"
                    value={formFilter.amt_from}
                    onChange={(event) => changeFilter("amt_from", event.target.value)}
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label>Total Pembayaran Maksimal</label>
                  <input
                    type="number"
                    className="form-control"
                    value={formFilter.amt_until}
                    onChange={(event) => changeFilter("amt_until", event.target.value)}
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label>Tanggal Billing Dari</label>
                  <input
                    type="date"
                    className="form-control"
                    value={formFilter.created_start_date}
                    onChange={(event) => changeFilter("created_start_date", event.target.value)}
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label>Tanggal Billing Sampai</label>
                  <input
                    type="date"
                    className="form-control"
                    value={formFilter.created_end_date}
                    onChange={(event) => changeFilter("created_end_date", event.target.value)}
                  />
                </div>
              </div>
              <div className="col-sm">
                <button
                  className="btn btn-primary"
                  onClick={() => applyFilter()}
                  style={{ marginTop: "32px" }}
                >
                  Filter
                </button>
                <button
                  className="btn btn-default"
                  onClick={() => resetFilter()}
                  style={{ marginTop: "32px" }}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>

          <AlertBox {...alert} setAlert={setAlert} />

          <div style={{ overflowX: "auto" }}>
            <table className="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th>No. Billing</th>
                  <th>No. Reg/Pelanggan</th>
                  <th>Nama</th>
                  <th>Total Pembayaran</th>
                  <th>Status</th>
                  <th>Jatuh Tempo Pembayaran</th>
                  <th>Jatuh Tempo Layanan</th>
                  <th>Periode Berikutnya</th>
                  <th>Konfirmasi Pembayaran</th>
                  <th>Metode</th>
                  <th>Paket</th>
                  <th>Tipe</th>
                  <th>Project</th>
                </tr>
              </thead>
              <tbody>
                {billings.data.length === 0 && (
                  <tr>
                    <td colSpan={15} className="text-center">
                      Tidak ada data
                    </td>
                  </tr>
                )}
                {billings.data.map((billing, index) => (
                  <tr key={index}>
                    {/* Nomor Billing */}
                    <td className="text-nowrap">{billing.billing_number || "-"}</td>
                    {/* Nomor Pelanggan / Registrasi */}
                    <td>
                      {billing.type === "REGISTRATION"
                        ? billing.registration.registration_number
                        : billing.customer.customer_number}
                    </td>
                    {/* Nama Pelanggan */}
                    <td className="text-nowrap">
                      {billing.type === "REGISTRATION"
                        ? billing.registration.name
                        : billing.customer.name}
                    </td>
                    {/* Total Pembayaran */}
                    <td className="text-nowrap">Rp. {convertToRupiah(billing.final_amount)}</td>
                    {/* Status */}
                    <td>{billing.status}</td>
                    {/* Jatuh Tempo Pembayaran */}
                    <td className="text-nowrap">
                      {moment.unix(billing.due_date).format("DD MMM YYYY")}
                    </td>
                    {/* Jatuh Tempo Layanan */}
                    <td className="text-nowrap">
                      {billing.type === "REGISTRATION"
                        ? "-"
                        : billing.plan_end_date
                        ? moment.unix(billing.plan_end_date).format("DD MMM YYYY")
                        : "-"}
                    </td>
                    {/* Periode Berikutnya */}
                    <td className="text-nowrap">
                      {billing.payment?.start_period
                        ? moment(billing.payment.start_period).format("DD MMM YYYY") +
                          " - " +
                          moment(billing.payment.end_period).format("DD MMM YYYY")
                        : "-"}
                    </td>
                    {/* Konfirmasi Pembayaran */}
                    <td className="text-nowrap">
                      {billing.status === "UNPAID"
                        ? "-"
                        : billing.confirmed_date
                        ? moment.unix(billing.confirmed_date).format("DD MMM YYYY - HH:mm")
                        : "-"}
                    </td>
                    {/* Metode */}
                    <td className="text-nowrap">{billing.payment.payment_method || "-"}</td>
                    {/* Paket */}
                    <td>{billing.internet_package.name}</td>
                    {/* Tipe Billing */}
                    <td>{BILLING_TYPE[billing.type] || "-"}</td>
                    {/* Nama Pelanggan */}
                    <td className="text-nowrap">
                      {billing.type === "REGISTRATION"
                        ? billing.registration.project?.name || "-"
                        : billing.customer.project?.name || "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mt-4">
            {billings.meta.count > billings.meta.page_size && (
              <Pagination
                totalpage={Math.ceil(billings.meta.count / billings.meta.page_size)}
                selectedpage={billings.meta.page}
                clickpage={(page) => gotoPage(page)}
                next={() => gotoPage(billings.meta.page + 1)}
                prev={() => gotoPage(billings.meta.page - 1)}
              />
            )}
          </div>
        </Box>
      </div>

      <ModalDownloadBillingPartner
        show={showModalDownload}
        onClose={toggleModalDownload}
        onSuccess={onSuccess}
      />
    </MainLayout>
  );
}
